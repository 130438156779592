import React from "react";
import "./learn.css";

const LearnInfo = ({ title, content, image }) => {
  return (
    <div className="learn">
      <div className="learn-card">
        <div>
          <h5 className="learn-card-heading">{title}</h5>
        </div>
        <iframe width="424" height="238" src={content} allowFullScreen></iframe>
      </div>
    </div>
  );
};

export default LearnInfo;
