import { services } from "../../services";
let networkId = 97;
async function fetchNetworkId() {
  networkId = await services.getNetworkId();
}
fetchNetworkId();

// 7: "0xb97Da845F01dC7d12606985505904bf1948228bA",
// 14: "0x1EE137E5699267A233f2a876AED8ecc77c0A03a4",
// 30: "0xf5A397B7a18e92a21504fBcaBD8be3b0dF462daF",
// 60: "0x9Ec350f83caB91094CcB97082743210Dc194E717",
function getContractAddresses() {
  if (networkId === "0x61" || +networkId === 97)
    return {
      pancakeLP: "0x74fA517715C4ec65EF01d55ad5335f90dce7CC87",
      headshotsToken: "0x73b84604C7b67317F6112ad50227A790Fd306628", //'0xd82fE6bf9dCE7EC68a8F455f20f04007cBF845e7',
      // 1:"0x668f0d70A500D50a86Fb887eB94fB1D670F1b59d", // 0x0D97e64B912e4399E6fF033d99191fD6Dc701D3a
      // 2:"0xC072b702C9aCE605D47F8563F103EddAAc2Fc73d",
      // 7: "0x47E7dDfF32643c1C359aE23576cc0f9e50a37463",
      // 14: "0x1b83895Ed19da3FB0fC66542F21E176F271A6465",
      // 30: "0xdF0AD9443994c42698c5d9F5a0C34093Db065320",
      // 60: "0x76eac6aeBe65eadc7e0d4BbAe17d1e38f1837c3a",
      // 90: "0x9a3C8e8402220a228ad1fB99a669f98e79B20ffb",
      // 180: "0x5D4F8e9f59d3EEa9Bd6200573149D3ca71e1F02b",
      farmingContract: {
        pancakeSwap: {
          contract: "0x5e455eed60f5ec367fb7bd3bdd0d40618eedd522",  //0x37F27d45dF100020d4B7a036374082Acda683aED
          lpToken: "0x73b84604C7b67317F6112ad50227A790Fd306628",   //0x7593AFc6594Ae138804d55ca29bBCD193A83Ddf7
        },
        bakerySwap: {
          contract: "0x5b40238E06B2a8bEef5de1C4AD329E52902Eb8BB",
          lpToken: "0xc2Eed0F5a0dc28cfa895084bC0a9B8B8279aE492",
        },
      },
    };
  else if (+networkId === 56 || networkId === "0x38")
    return {
      pancakeLP: "0x74fA517715C4ec65EF01d55ad5335f90dce7CC87",
      headshotsToken: "0x73b84604C7b67317F6112ad50227A790Fd306628", //'0xd82fE6bf9dCE7EC68a8F455f20f04007cBF845e7',
      7: "0xfe0905A8CdFd2a95Cf8b7d481F8EEE31425a7CA5",
      14: "0x38cbA721edcAE3AC2aaE806648cB855A6d7b0fe9",
      // 30: "0xdF0AD9443994c42698c5d9F5a0C34093Db065320",
      // 60: "0x76eac6aeBe65eadc7e0d4BbAe17d1e38f1837c3a",
      // 90: "0x9a3C8e8402220a228ad1fB99a669f98e79B20ffb",
      // 180: "0x5D4F8e9f59d3EEa9Bd6200573149D3ca71e1F02b",
      farmingContract: {
        pancakeSwap: {
          contract: "0x1F10564BAD9367CfF4247A138eBbA9a9aaeb789E",  //0x1F10564BAD9367CfF4247A138eBbA9a9aaeb789E
          lpToken: "0x74fa517715c4ec65ef01d55ad5335f90dce7cc87",   //0x74fa517715c4ec65ef01d55ad5335f90dce7cc87
        },
        bakerySwap: {
          contract: "0x1544be2dC66eaE3E91d983c6D27c9CB1CDe74AcF",
          lpToken: "0x782f3f0d2b321D5aB7F15cd1665B95EC479Dcfa5",
        },
      },
      oldFarmingContract: {
        pancakeSwap: {
          contract: "0x7439bCF0B97ecd7f3A11c35Cc2304F01Eaf04fC0",
          lpToken: "0x74fa517715c4ec65ef01d55ad5335f90dce7cc87",
        },
        bakerySwap: {
          contract: "0x1272B728B8964e75786c0f1772033719C0Fa5eAc",
          lpToken: "0x782f3f0d2b321D5aB7F15cd1665B95EC479Dcfa5",
        },
      },
    };
  else
    return {
      pancakeLP: "0x74fA517715C4ec65EF01d55ad5335f90dce7CC87",
      headshotsToken: "0x73b84604C7b67317F6112ad50227A790Fd306628", //'0xd82fE6bf9dCE7EC68a8F455f20f04007cBF845e7',
      7: "0xfe0905A8CdFd2a95Cf8b7d481F8EEE31425a7CA5",
      14: "0x38cbA721edcAE3AC2aaE806648cB855A6d7b0fe9",
      // 30: "0xdF0AD9443994c42698c5d9F5a0C34093Db065320",
      // 60: "0x76eac6aeBe65eadc7e0d4BbAe17d1e38f1837c3a",
      // 90: "0x9a3C8e8402220a228ad1fB99a669f98e79B20ffb",
      // 180: "0x5D4F8e9f59d3EEa9Bd6200573149D3ca71e1F02b",
      farmingContract: {
        pancakeSwap: {
          contract: "0x5e455eed60f5ec367fb7bd3bdd0d40618eedd522",
          lpToken: "0x73b84604C7b67317F6112ad50227A790Fd306628",
        },
        bakerySwap: {
          contract: "0x1544be2dC66eaE3E91d983c6D27c9CB1CDe74AcF",
          lpToken: "0x782f3f0d2b321D5aB7F15cd1665B95EC479Dcfa5",
        },
      },
      oldFarmingContract: {
        pancakeSwap: {
          contract: "0x7439bCF0B97ecd7f3A11c35Cc2304F01Eaf04fC0",
          lpToken: "0x74fa517715c4ec65ef01d55ad5335f90dce7cc87",
        },
        bakerySwap: {
          contract: "0x1272B728B8964e75786c0f1772033719C0Fa5eAc",
          lpToken: "0x782f3f0d2b321D5aB7F15cd1665B95EC479Dcfa5",
        },
      },
    };
}
export default getContractAddresses;
