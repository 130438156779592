export const learnData = [
  {
    title: "WHAT IS WEB 3.0? Explained By Fibitpro..",
    content: "https://www.youtube.com/embed/gBmhleAf3CQ",
  },
  {
    title: " WHAT IS METAMASK? A Short Brief by Fibitpro.",
    content: "https://www.youtube.com/embed/UE2uM1PKgdc",
  },

  {
    title: " What is Yield Farming in Hindi By Fibitpro",
    content: "https://www.youtube.com/embed/J3VQJFOKPXE",
  },

  {
    title: "What is Liquidity Pool? | Simply Explained By Fibitpro",
    content: "https://www.youtube.com/embed/2-fCDi_ggLc",
  },
  {
    title: "Earn Exponentially By Doing Crypto Farming.",
    content: "https://www.youtube.com/embed/X9dmNEPPRnc",
  },
  {
    title: "WHAT IS DEX ? Simply Explained By Fibitpro.",
    content: "https://www.youtube.com/embed/L4M-XxjPJdU",
  },
];
