import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "./header-new.css";
import logo from "../assets/logo.png";
import medium from "../assets/medium.svg";
import "react-toastify/dist/ReactToastify.css";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Collapse from "@kunukn/react-collapse";

import TelegramIcon from "@material-ui/icons/Telegram";
import TwitterIcon from "@material-ui/icons/Twitter";
import MetaIcon from "../assets/metamask.png";
import TrustIcon from "../assets/tw.png";
import { metamaskConnectInit, web3 } from "../web3";
import { compactAddress } from "../helpers/function";
import { actions } from "../actions";
import GoogleTranslate from "../pages/GoogleTranslate";
import SamplePage from "../pages/GoogleTranslate";
const closeIcon = (
  <svg fill="currentColor" viewBox="0 0 30 30" width={40} height={40}>
    <line
      x1="15"
      y1="15"
      x2="25"
      y2="25"
      stroke="#000"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-miterlimit="16"
    ></line>
    <line
      x1="25"
      y1="15"
      x2="15"
      y2="25"
      stroke="#000"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-miterlimit="16"
    ></line>
  </svg>
);

const HeaderNew = (props) => {
  const { web3Data } = props;
  const [networkId, setNetworkId] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [disconnectModal, setDisconnectModal] = useState(false);
  useEffect(() => {
    window.ethereum?.on("accountsChanged", (accounts) => {
      if (accounts[0]) {
        props.getWeb3();
      }
      // else {
      //   callWeb3(true);
      // }
    });
    window.ethereum?.on("chainChanged", (chainId) => {
      window.location.reload();
    });
  }, []);
  useEffect(() => {
    if (web3Data.accounts[0]) {
      setOpen(false);
      getUserData(web3Data);
      // props.getKYCData(web3Data.accounts[0]);
      if (web3Data.isLoggedIn) checkLogOut();
    }
  }, [web3Data.accounts[0]]);
  useEffect(() => {
    if (!+localStorage.getItem("disconnected")) props.getWeb3();
  }, []);

  const getUserData = async (web3Data) => {
    setNetworkId(await web3.eth.getChainId());
  };
  const [open, setOpen] = useState(false);

  const onCloseModal = () => setOpen(false);

  const checkLogOut = () => {
    const interValCheck = setInterval(() => {
      web3.eth.getAccounts().then((resp) => {
        if (!resp[0]) {
          props.getWeb3(1);
          clearInterval(interValCheck);
        }
      });
    }, 1000);
  };
  const toggle = (index) => {
    setIsOpen(!isOpen);
    setCollapse(!collapse);
  };

  const [collapse, setCollapse] = useState(false);

  const onInit = ({ state, style, node }) => {
    setIsOpen(true);
  };
  return (
    <div>
      <header className="hea_der">
        <div className="container_cust">
          <div className="inner_header">
            <div className="logo">
              <a href="/" target="">
                <img src={logo} alt="" />
              </a>
              {/* <Link to="/">
                <img src={logo} alt="" />
              </Link> */}
            </div>
            <div className="navi_gation">
              <Link className="gen_btn btn_white" to="/">
                dashboard
              </Link>

              <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={toggle}
                >
                  <span className="navbar-toggler-icon"></span>
                </button>

                <Collapse
                  onInit={onInit}
                  isOpen={collapse}
                  aria-hidden={isOpen ? "false" : "true"}
                >
                  <div className="tabmenu-list">
                    {/* <ul className="navbar-nav white-ul">
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          Projects
                        </a>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/staking">
                          Staking/Farming
                        </Link>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          Whitelist
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          Whitepaper
                        </a>
                      </li>
                    </ul> */}
                    <ul className="navbar-nav icon-nav tab-div">
                      <li className="nav-item ">
                        <a
                          className="nav-link"
                          href="#"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Link className="nav-link" to="/">
                            Staking/Farming
                          </Link>
                        </a>
                      </li>
                      <li className="nav-item">
                    {/* <span class="upcomingtn">UpComing</span> */}
                    <Link className="nav-link" to="/faq">
                      FAQ
                    </Link>
                  </li>
                  <li className="nav-item">
                    {/* <span class="upcomingtn">UpComing</span> */}
                    <Link className="nav-link" to="/learn">
                      learning
                    </Link>
                  </li>
                      {/* <li className="nav-item">
                        <a
                          className="nav-link"
                          href="https://seedifyfund.medium.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span>
                            <img src={medium} alt="" />{" "}
                          </span>
                        </a>
                      </li> */}
                      {/* <li className="nav-item">
                        <a
                          className="nav-link"
                          href="#"
                          // target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          <TelegramIcon />
                          Marketplace
                        </a>
                      </li> */}

                      <li className="nav-item">
                        <a className="nav-link" rel="noopener noreferrer">
                          <button
                            className="gen_bttn btn_white connect_btn yellow_btn mobile-div"
                            onClick={() => {
                              if (web3Data.isLoggedIn) setDisconnectModal(true);
                              else {
                                setOpen(true);
                                localStorage.setItem("disconnected", 0);
                              }
                            }}
                          >
                            {web3Data.accounts[0]
                              ? networkId === 56 ||
                                networkId === "0x38" ||
                                networkId === 97 ||
                                networkId === "0x61"
                                ? compactAddress(web3Data.accounts[0])
                                : "Wrong Network"
                              : "Connect wallet"}
                          </button>
                        </a>
                      </li>

                      {/* 
                      <li>
                        <a href="" className="nav-link">
                          <button
                            className="gen_bttn btn_white connect_btn yellow_btn mobile-div"
                            onClick={() => {
                              if (web3Data.isLoggedIn) setDisconnectModal(true);
                              else {
                                setOpen(true);
                                localStorage.setItem("disconnected", 0);
                              }
                            }}
                          >
                            {web3Data.accounts[0]
                              ? networkId === 56 ||
                                networkId === "0x38" ||
                                networkId === 97 ||
                                networkId === "0x61"
                                ? compactAddress(web3Data.accounts[0])
                                : "Wrong Network"
                              : "Connect wallet"}
                          </button>
                        </a>
                      </li> */}
                    </ul>
                    {/* <button
                      className="gen_bttn btn_white connect_btn yellow_btn mobile-div"
                      onClick={() => {
                        if (web3Data.isLoggedIn) setDisconnectModal(true);
                        else {
                          setOpen(true);
                          localStorage.setItem("disconnected", 0);
                        }
                      }}
                    >
                      {web3Data.accounts[0]
                        ? networkId === 56 ||
                          networkId === "0x38" ||
                          networkId === 97 ||
                          networkId === "0x61"
                          ? compactAddress(web3Data.accounts[0])
                          : "Wrong Network"
                        : "Connect wallet"}
                    </button> */}
                  </div>
                </Collapse>
                {/* <div
                  class="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav white-ul">
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        Projects
                      </a>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/staking">
                        Staking/Farming
                      </Link>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        Whitelist
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        Whitepaper
                      </a>
                    </li>
                  </ul>
                </div> */}
              </nav>
              {/* Nav bar list Desktop */}
              <div className="nav-item d-tab">
                <ul className="navbar-nav icon-nav">
              
                  <li className="nav-item">
                    {/* <span class="upcomingtn">UpComing</span> */}
                    <Link className="nav-link" to="/">
                      Staking/Farming
                    </Link>
                  </li>
                  <li className="nav-item">
                    {/* <span class="upcomingtn">UpComing</span> */}
                    <Link className="nav-link" to="/faq">
                      FAQ
                    </Link>
                  </li>
                  <li className="nav-item">
                    {/* <span class="upcomingtn">UpComing</span> */}
                    <Link className="nav-link" to="/learn">
                      learning
                    </Link>
                  </li>
                  {/* <li><link to="/staking">Staking/Farming</link></li> */}
                  {/* <li>
                    <span class="upcomingtn">UpComing</span>
                    <a href="">Marketplace</a>
                  </li> */}
                  {/* <li className="nav-item ">
                    <a
                      className="nav-link"
                      href="https://twitter.com/SeedifyFund"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>
                        <TwitterIcon />
                      </span>{" "}
                    </a>
                  </li> */}
                  {/* <li className="nav-item">
                    <a
                      className="nav-link"
                      href="https://seedifyfund.medium.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>
                        <img src={medium} alt="" />
                      </span>
                    </a>
                  </li> */}
                  {/* <li className="nav-item">
                    <a
                      className="nav-link"
                      href="https://t.me/seedifyfund "
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      <TelegramIcon />
                    </a>
                  </li> */}
                  <li>
                    <a>
                      <button
                        className="gen_bttn btn_white connect_btn yellow_btn"
                        onClick={() => {
                          if (web3Data.isLoggedIn) setDisconnectModal(true);
                          else {
                            setOpen(true);
                          }
                        }}
                      >
                        {web3Data.accounts[0]
                          ? networkId === 56 ||
                            networkId === "0x38" ||
                            networkId === 97 ||
                            networkId === "0x61"
                            ? compactAddress(web3Data.accounts[0])
                            : "Wrong Network"
                          : "Connect wallet"}
                      </button>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a>
                      {" "}
                      {/* <GoogleTranslate /> */}
                      <SamplePage />
                    </a>
                  </li>
                </ul>
                {/* {metamask.active ? ( */}
                {/* <button
                  className="gen_bttn btn_white connect_btn yellow_btn"
                  onClick={() => {
                    if (web3Data.isLoggedIn) setDisconnectModal(true);
                    else {
                      setOpen(true);
                    }
                  }}
                >
                  {web3Data.accounts[0]
                    ? networkId === 56 ||
                      networkId === "0x38" ||
                      networkId === 97 ||
                      networkId === "0x61"
                      ? compactAddress(web3Data.accounts[0])
                      : "Wrong Network"
                    : "Connect wallet"}
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* {isOpen ? (
        <BlackWrap>
          <WhiteBX01>
            <CloseBTN
              onClick={() => {
                toggle(1);
              }}
            >
              x
            </CloseBTN>
            <LightBox>
              <div className="modal-content">
                <div className="w50">
                  <button
                    onClick={() => {
                      metamaskConnectInit();
                      props.enableMetamask();
                    }}
                  >
                    <img src={MetaIcon} alt="" />
                    <p>Metamask</p>
                  </button>
                </div>
                <div className="w50">
                  <button onClick={() => props.enabledWalletConnect()}>
                    <img src={TrustIcon} alt="" />
                    <p>Wallet Connect</p>
                  </button>
                </div>
              </div>
            </LightBox>
          </WhiteBX01>
        </BlackWrap>
      ) : null} */}

      <div>
        <Modal
          classNames={{ modal: "customModal1" }}
          open={open}
          onClose={onCloseModal}
          center
        >
          <div className="modal-box">
            <div
              className="modal-box-inner"
              onClick={() => {
                metamaskConnectInit();
                props.enableMetamask();
              }}
            >
              <img src={MetaIcon} alt="" />
              <h3>Metamask</h3>
              <p>Connect to your MetaMask Wallet</p>
            </div>
            <div
              className="modal-box-inner"
              onClick={() => props.enabledWalletConnect()}
            >
              <img src={TrustIcon} alt="" />
              <h3>Wallet Connect</h3>
              <p>Scan with WalletConnect to connect</p>
            </div>
          </div>
        </Modal>
        <Modal
          classNames={{ modal: "customModal2" }}
          open={disconnectModal}
          onClose={() => setDisconnectModal(false)}
          // closeIcon={stateVars.closeIcon}
          closeIcon={closeIcon}
          center
        >
          <div className="withdraw-box">
            {/* <h2>{currentFunction === "stake" ? "Stake" : "Withdraw"}</h2> */}
            <label>Disconnect the wallet</label>
            <input
              className="form-control fc2"
              defaultValue={web3Data.accounts[0]}
              disabled={true}
            />
            <div className="form-grp"></div>
            <button
              className="approve-stake-btn"
              onClick={() => {
                localStorage.setItem("disconnected", 1);
                props.getWeb3(true);
                setDisconnectModal(false);
              }}
            >
              Disconnect
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
};
// const FlexDiv = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-wrap: wrap;
// `;
// const MainSection = styled(FlexDiv)`
//   background: url(${Mainbg}) no-repeat;
//   background-size: cover;
//   // height: 100vh;
//   position: relative;
// `;

const mapDipatchToProps = (dispatch) => {
  return {
    getWeb3: (val) => dispatch(actions.getWeb3(val)),
    enableMetamask: () => dispatch(actions.enableMetamask()),
    enabledWalletConnect: () => dispatch(actions.enabledWalletConnect()),

    // getKYCData: (address) => dispatch(actions.getKYCData(address)),
  };
};
const mapStateToProps = (state) => {
  return {
    web3Data: state.fetchWeb3Data,
    kycData: state.fetchKYCData,
  };
};
export default connect(mapStateToProps, mapDipatchToProps)(HeaderNew);
