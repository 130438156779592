import React from "react";
import HeaderNew from "../../components/header-new";
import Footer from "../../components/footer";
import { learnData } from "./learnData";
import LearnInfo from "./learnInfo";
import "./learn.css";

const Learning = () => {
  const { title, content, image } = learnData;
  return (
    <div>
      <HeaderNew />

      <div>
        <h2 className="learn-heading">Fibitpro Web3.0 Education</h2>

        <div className="grid-view">
          {learnData.map(({ title, content }) => (
            <LearnInfo title={title} content={content} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Learning;
