import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import "./footer.css";
import medium from "../assets/medium.svg";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import TelegramIcon from "@material-ui/icons/Telegram";

const Footer = () => (
  <div className="footer">
    <div className="footer-logo">
      <a href="https://fibitpro.com/">
        <img src={logo} alt="" />
      </a>
    </div>
    <div className="social">
      <h5>Social media</h5>
      <div className="social-links">
        <ul>
          <li>
            <a
              href="https://www.instagram.com/accounts/login/?next=/fibitproexchange/"
              target="blank"
            >
              <InstagramIcon />
            </a>
          </li>
          <li>
            
          <a href="https://www.linkedin.com/authwall?trk=gf&trkInfo=AQHVNegM1cXPQQAAAYFiHqcoH8lj2Gi9VryZh6qECoTgjs-CtSk7rBhmXnxoyHEi8va6mJ7deUnsGxzXCqnWHBL9x9lUhzEF-77Alp0TGYkbyJ6FYXxgHfkqou-2EGq9IqyJTps=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Ffibits" target="blank">
              
              <LinkedInIcon />

            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/fibitproexchange/" target="blank">
              <FacebookIcon />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/fibitpro" target="blank">
              <TwitterIcon />
            </a>
          </li>
          <li>
            <a href="https://t.me/fibitpro" target="blank">
              <TelegramIcon />
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div className="social">
      <h5>Support</h5>
      <div className="support">
        <a href="mailto: support@fibitpro.com" target="blank">
          {" "}
          <h6>support@fibitpro.com</h6>
        </a>
        {/* <h6>support@fibitpro.com</h6> */}
      </div>
    </div> <br /> <br />
    <div className="footer-end">
      <p>© Fibit 2022 All rights reserved</p>
    </div>
  </div>
);

export default Footer;
