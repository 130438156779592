import React, { useEffect } from "react";
import './faq.css'

// const GoogleTranslate = (props) => {
//   useEffect(() => {
//     // in some cases, the google translate script adds a style to the opening html tag.
//     // this added style disables scrolling.
//     // the next 3 lines removes this added style in order to re-enable scrolling.
//     // window.document.getElementsByClassName("goog-logo-link").empty();
//     if (window.document.scrollingElement.hasAttribute("style")) {
//       window.document.scrollingElement.setAttribute("style", "");
//     }

//   });

//   return( <div id="google_translate_element"></div>);
// };

// export default GoogleTranslate;



const SamplePage = () => {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        includedLanguages: "hi,en,mr,bn,gu,kn,ml,ne,ur,te,ta,pa" ,
        layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
      },
      "google_translate_element"
    );
  };

  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  return (
    <div>
      {/* <h2 className="title gx-mb-4"><IntlMessages id="sidebar.samplePage"/></h2> */}
      <div id="google_translate_element"></div>
      {/* <div className="gx-d-flex justify-content-center">
         <h4>Start building your app. Happy Coding!</h4>
       </div> */}
    </div>
  );
};

export default SamplePage;
