import tokenAbi from "../contractData/abis/token.json";
import feStakingABI from "../contractData/abis/feStaking.json";
import getContractAddresses from "../contractData/contractAddress/addresses";
import farmingABI from "../contractData/abis/farming.json";
import lpTokenABI from "../contractData/abis/lpToken.json";
import pancakeLPABI from "../contractData/abis/pancakeLP.json";
import { web3 } from "../web3";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export function TimeStampToDateString(val) {
  const dateString = new Date(Number(val) * 1000);
  var year = dateString.getFullYear();
  // month as 2 digits (MM)
  var month = dateString.getMonth();
  // date as 2 digits (DD)
  var date = ("0" + dateString.getUTCDate()).slice(-2);
  var hrs = ("0" + dateString.getUTCHours()).slice(-2);
  var min = ("0" + dateString.getUTCMinutes()).slice(-2);
  let d = `${date} ${months[month]} ${year} ${hrs}:${min}`;
  return d;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function compactAddress(address) {
  const newAddress = address;
  if (address) {
    const res =
      newAddress.substring(0, 4) +
      "..." +
      newAddress.substring(newAddress.length - 4, newAddress.length);
    return res;
  }
}

export function getContractInstance(contractAddress,contractType) {

  let currentaddress = contractAddress;

  // let currentABI = contract === "headshotsToken" ? tokenAbi : feStakingABI;
  let currentABI = undefined;

  if(contractType === "fbtToken"){
    currentABI = tokenAbi
  }
  if(contractType === "stake"){
    currentABI= feStakingABI
  }

  if (contractType === "farming") {
    currentABI = farmingABI;
  }
  if (contractType === "lpToken") {
    currentABI = lpTokenABI;
    
  }
  if (contractType === "pancakeLP") {
    currentABI = pancakeLPABI;
  }

  try {
    if (web3) {
      const contractInstance = new web3.eth.Contract(
        currentABI,
        currentaddress
      );
      return contractInstance;
    }
  } catch (error) {
    // console.log(error);
  }
}
