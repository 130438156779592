import React from "react";
import HeaderNew from "../components/header-new";
import Footer from "../components/footer";
import "./faq.css";
import { useState } from "react";
import Accordion from "./accordian";
import { accordionData } from "./faqData";
import GoogleTranslate from "./GoogleTranslate";
import { MdLanguage } from "react-icons/all";

function Faq() {
  const { title, content, image } = accordionData;

  return (
    <div>
      <HeaderNew />
      <div className="faq-heading">
        <h2>Frequently Asked Questions</h2>
        <p> Farming on Fibit is easy!</p>
      </div>
      {/* <div className="faq-heading">
        <iframe
          width="424"
          height="238"
          src="https://www.youtube.com/embed/J3VQJFOKPXE"
          title="यील्ड फार्मिंग क्या है? इससे पैसे कैसे कमा सकते है| What is Yield Farming in Hindi By Fibitpro"
          allowFullScreen
        ></iframe>
      </div> */}
      <div className="accordion-item">
        <div className="accordion">
          {accordionData.map(({ title, content, image }) => (
            <Accordion title={title} content={content} image={image} />
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Faq;
